import { Locale, type LocalizedString } from "../Locale.js";
import type { Tenant } from "../Tenant.js";
import { getPathInLocale } from "./getPathInLocale.js";

export function getHireItTeamPath(tenant: Tenant): string {
	const pathMap: LocalizedString = {
		[Locale.cs]: "/it-sluzby/pronajem-tymu",
		[Locale.en]: "/it-services/hire-it-team",
	};

	return getPathInLocale(tenant.locale, pathMap);
}
